import { useState } from "react";
import { Theme } from "react-daisyui";
import Menu from "./components/menu/menu";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RebornHomePage from "./containers/reborn-home";
import ClassicHomePage from "./containers/classic-home";
import UnsubscribePage from "./components/unsubscribe-page"; // Import the new component

function App() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
      <Theme dataTheme="dark">
        <Router>
          <div className="App min-h-screen">
            <header className="App-header fixed top-0 left-0 z-10 w-full">
              <Menu toggleOpen={toggleOpen} />
            </header>
            <main className="App-main">
              <Routes>
                <Route exact path='/' element={< RebornHomePage />}></Route>
                <Route exact path='/classic' element={< ClassicHomePage />}></Route>
                <Route exact path='/unsubscribe' element={< UnsubscribePage />}></Route> {/* Add the route with accountId param */}
              </Routes>
            </main>
          </div>
        </Router>
      </Theme>
  );
}

export default App;